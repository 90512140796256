import React  from "react"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// import About from "./Pages/General/About";
import './App.css'

// import Footer from "./COMPONENTS/Footer";

import NavBAR from "./components/Navbar";
import About from "./components/Pages/About";


function App() {
  return (
     
        <Router>
           
           <NavBAR />
            <Routes> 
            <Route path="/" element={<About />} />
           
           
            
          
            </Routes>
            {/* <Footer /> */}
        </Router>
    
  );
}

export default App;
