import  app  from '../fire';
import { doc, setDoc, getFirestore } from "firebase/firestore"; 
async function PostEmail(email){
    const db = getFirestore(app)
    // unix timestamp
    let date = new Date()
    let time = date.toLocaleDateString()
    let unix = date.getTime().toString()

   

    const data = {
       
        email: email,
        time: time,
        unix: unix
    };
    await setDoc(doc(db, "NewsletterList", email), data);

}

export default PostEmail