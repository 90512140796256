const { getFirestore } = require('firebase/firestore');
const { initializeApp } = require('firebase/app');
const { getAuth } = require("firebase/auth");
const { getFunctions } = require('firebase/functions');

const firebaseConfig = {
  apiKey: "AIzaSyCsd7P4YFJi9_2AVobZsxCgrX_PMmpaTAI",
  authDomain: "izubalo.firebaseapp.com",
  projectId: "izubalo",
  storageBucket: "izubalo.appspot.com",
  messagingSenderId: "485992006624",
  appId: "1:485992006624:web:7bb2499993898eff736185",
  measurementId: "G-QMBGYTH5MR"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app)
  export const db = getFirestore(app)
  
  
  export const fn = getFunctions(app, "us-central1")
  export default app