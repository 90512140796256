
import {
    InputGroup,
    Form,
    Navbar,
    Container,
    Card, 
    Button, 
    Col,
    Row

} from 'react-bootstrap'
import SignUp from '../Signup'



const About = ({navigation}) =>{
   
    return (
        <div className='flex justify-center'>
            
     
           
          
            <Container >
        <Row className='mt-4'>
            <Col>
            <Row>
                {/* <PatchCheck  color='gray' className=" mx-auto my-auto"  size={50}  /> */}
                </Row>
                    <Row className="mt-3 mx-auto text-center">
                        <h4 className="mx-auto" style={{color: 'gray'}}>Join Our Newsletter!</h4>
                        <p style={{color: 'gray'}} > In our authentic and relatable newsletter, we explore the journey of entrepreneurs, discussing their failures, setbacks, and pivotal life events that shaped them before success. Join us for a rich and inspiring discussion on the real experiences and funny anecdotes from the industry!
                        </p>
                    </Row>
                </Col>

        </Row>
        <SignUp />

        </Container>
      
        

            </div>)
}
export default About