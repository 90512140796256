import {Navbar, Nav, Button, Form, Container, InputGroup} from 'react-bootstrap'

import ReactLogo from '../components/MEDIA/BAREFOOTBOARDROOM.png';

const NavBAR = () =>{

    
    
  
   

    return(
        <>
        <Navbar expand="lg" style={{backgroundColor: 'white'}} className="bg-body-tertiary">
          
    <Container>
    <img className="mr-5" src={ReactLogo} style={{'height':'75px','width':'697px'}} alt="React Logo"/>
    
    <>
    
      </>
     

    </Container>
  </Navbar>

  

  </>
   



    )
}
export default NavBAR