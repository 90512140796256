import React, { useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Row, Alert, Modal, Spinner } from "react-bootstrap"
import { Link,  } from "react-router-dom"
import PostEmail from "../API/PostEmail"
export default function SignUp() {
  const emailRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState("")

  async function handleSubmit(e) {

    e.preventDefault()
    setError("")
    setSuccess("")
  

   

    try {
        setLoading(true)
        await PostEmail(emailRef.current.value)
        setSuccess("Success!!!")
 
    } catch (e) {
      setError('Error... Try Again Later')
     
    }
    setLoading(false)
  }



 

  return (
    <>
    <br />
      <Row className='justify-content-center'>
        <Col lg={8}>
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Sign Up</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {success && <Alert variant="success">{success}</Alert>}
          <Form onSubmit={handleSubmit}>
              <Row className='mx-auto'>
              
          <Col>
         
          <Form.Group id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
           
               

           
          
           
          
          </Col>
          </Row>
           
           
            <Row>
           
            
            </Row>
       
            <div className='mt-3' />
           
                <Button  style={{backgroundColor: '#F68D55', borderColor: '#F68D55'}} className="btn-auth w-100" type="submit">
                    {loading ?
                    <>
                     <Spinner animation="border"role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                    </>
                  :
                  
                  <>
                  Sign Up
                  </>}
                    
                </Button>
                {/* </Link> */}
        
          </Form>
          
        </Card.Body>
      </Card>
     
      </Col>
    </Row>

    

    </>
  )
}